import React from "react"
import { useIntl, FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"
import Seo from "../../../components/SEO"
import Header from "../../../components/Header"
import PageNav from "../../../components/PageNav"

const Kiwa = (props) => {

    const intl = useIntl()

    return (
        <div>
            <Seo
                title={intl.formatMessage({ id: "texts.kiwa.title.seo" })}
            />
            <Header />

            <div className="sir-breadcrumbs">
                <Link class="text-sir-black-50" to="/"><FormattedMessage id="global.sir" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <Link class="text-sir-black-50" to="/great-love"><FormattedMessage id="global.menu.greatlove" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <Link class="text-sir-black-50" to="/great-love/texts"><FormattedMessage id="global.menu.texts" /></Link>
                <span class="text-sir-black-25 mx-1">/</span>
                <FormattedMessage id="texts.kiwa.author.surname" />
            </div>

            <div className="sir-section sir-layout-text">
                <p className="body-s uppercase text-center"><FormattedMessage id="texts.kiwa.author" /></p>
                <h1 className=""><FormattedMessage id="texts.kiwa.title" /></h1>

                <p><FormattedHTMLMessage id="texts.kiwa.text.p1" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p2" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p3" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p4" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p5" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p6" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p7" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p8" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p9" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p10" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p12" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p13" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p14" /></p>
                <p><FormattedHTMLMessage id="texts.kiwa.text.p15" /></p>
            </div>

            <PageNav />
        </div>
    )
}

export default Kiwa;